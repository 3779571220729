import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';
import { ProjectContextProvider } from './contexts/projectContext';

function App() {
  return (
    <ProjectContextProvider>
      <RouterProvider router={router} />
    </ProjectContextProvider>
  );
}

export default App;
