import React, { FC, memo, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Divider, message, Row } from 'antd';
import { PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons';

import AddTokenForm from './AddTokenForm';
import { requestApiTokens, requestCreateToken, requestDeleteToken, requestGetToken } from './api';
import Token from './Token';
import { IApiToken } from './Token/interfaces';
import AppCode from './AppCode';

const APIProjectSettings: FC = () => {
  const [isTokensLoading, setIsTokensLoading] = useState<boolean>(true);
  const [tokens, setTokens] = useState<IApiToken[]>([]);
  const [isAddTokenFormOpened, setIsAddTokenFormOpened] = useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const updateTokensList = useCallback(async () => {
    const [isRequestSucceed, reason] = await requestApiTokens(id!);
    if (!isRequestSucceed) {
      if (reason === 'project_not_found') {
        message.error('Project deleted');
        return navigate('/');
      }
    }

    const { apiTokens = [] } = reason;
    setTokens(apiTokens as IApiToken[]);
    setIsTokensLoading(false);
  }, [id, navigate]);

  useEffect(() => {
    updateTokensList();
  }, [updateTokensList]);

  const onAddTokenBtnClick = () => {
    setIsAddTokenFormOpened(true);
  }

  const onCloseAddTokenForm = () => {
    setIsAddTokenFormOpened(false);
  }

  const createToken = async (name: string) => {
    const [isRequestSucceed, reason] = await requestCreateToken(id!, name);
    if (!isRequestSucceed) {
      if (reason === 'project_not_found') {
        message.error('Project deleted');
        return navigate('/');
      }
    }

    updateTokensList();

    const { apiToken } = reason;

    navigator.clipboard.writeText(apiToken);
    message.info('Copied to clipboard');

    setIsAddTokenFormOpened(false);
  }

  const deleteToken = async (tokenId: string): Promise<boolean> => {
    const [isRequestSucceed] = await requestDeleteToken(tokenId, id!);
    if (isRequestSucceed) {
      updateTokensList();
    }

    return isRequestSucceed;
  }

  const copyToken = async (tokenId: string): Promise<boolean> => {
    const [isRequestSucceed, reason] = await requestGetToken(tokenId, id!);

    if (!isRequestSucceed) {
      if (reason === 'project_not_found') {
        message.error('Project deleted');
        navigate('/');
        return false;
      }

      if (reason === 'token_not_found') {
        message.error('Token not found');
        updateTokensList();
        return false;
      }

      message.error('Cannot copy token');
      updateTokensList();
    }

    const { apiToken } = reason;

    navigator.clipboard.writeText(apiToken);
    message.info('Copied to clipboard');

    return true;
  }

  return (
    <>
      <h1>
        Api settings
      </h1>
      <Row>
        <h4>
          API tokens  {' '}
          <Button type='text' icon={<PlusCircleOutlined />} onClick={onAddTokenBtnClick}>Add token</Button>
        </h4>
      </Row>
      <Row>
        <Col span={12}>
          {isAddTokenFormOpened && (
            <AddTokenForm closeForm={onCloseAddTokenForm} submitForm={createToken} />
          )}

          {isTokensLoading && (
            <LoadingOutlined />
          )}

          {tokens.map((token) => (
            <Token
              name={token.name}
              deleteToken={() => deleteToken(token._id)}
              changeIsActive={() => false}
              isActive={token.isActive}
              copyToken={() => copyToken(token._id)}
              key={token._id}
            />
          ))}
        </Col>
      </Row>
      <Divider />
      <Row>
        <h4>Set up</h4>
      </Row>
      <Row>
        <Col span={12}>
          <p>Copy and paste the Affilink code into the &lt;head&gt; element of your website or app</p>
          <AppCode />
        </Col>
      </Row>
    </>
  );
}

export default memo(APIProjectSettings);
