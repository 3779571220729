import React, { FC, memo } from 'react';

import MainLayout from '../../Components/Layout';
import AddPartnerForm from '../../Components/AddPartnerForm';

const CreatePartnerPage: FC = () => {
  return (
    <MainLayout showSider={true}>
      <AddPartnerForm />
    </MainLayout>
  );
}

export default memo(CreatePartnerPage);
