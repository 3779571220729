import { FormRule } from 'antd';

export const fixPriceRule: FormRule[] = [
  {
    required: true,
    message: 'Fix price should be exists',
  },
  {
    type: 'number',
    min: 0,
    message: 'Minimal value is 0',
  },
];

export const percentRule: FormRule[] = [
  {
    required: true,
    message: 'Percents should be exists',
  },
  {
    type: 'number',
    min: 0,
    message: 'Minimal value is 0',
  },
  {
    type: 'number',
    max: 100,
    message: 'Maximum value is 100',
  },
];

export const pendingPeriodRule: FormRule[] = [
  {
    required: true,
    message: 'Pending period should be exists',
  },
  {
    type: 'number',
    min: 0,
    message: 'Minimal value is 0',
  },
  {
    type: 'number',
    max: 30,
    message: 'Maximum value is 30',
  },
];
