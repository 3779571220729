import React, { FC, memo } from 'react';
import { Card, Space, Statistic } from 'antd';

import './partnerStatistic.css';

interface IPartnerStatistic {
  clicks: number;
  payments: number;
  registrations: number;
  transactionsAmount: number;
  balance: number;
}
const PartnerStatistic: FC<IPartnerStatistic> = (props) => {
  return (
    <Space wrap={true} size='middle'>
      <Card className='single_partner__partner_statistic__statistic_card' bordered={true}>
        <Statistic title='Clicks' value={props.clicks} />
      </Card>

      <Card className='single_partner__partner_statistic__statistic_card' bordered={true}>
        <Statistic title='Registrations' value={props.registrations} />
      </Card>

      <Card className='single_partner__partner_statistic__statistic_card' bordered={true}>
        <Statistic title='Payments count' value={props.payments} />
      </Card>

      <Card className='single_partner__partner_statistic__statistic_card' bordered={true}>
        <Statistic title='Transactions amount' value={props.transactionsAmount / 100} />
      </Card>

      <Card className='single_partner__partner_statistic__statistic_card' bordered={true}>
        <Statistic title='Balance' value={props.balance / 100} />
      </Card>
    </Space>
  );
}

export default memo(PartnerStatistic);
