import React, { FC, memo } from 'react';
import { Col, Row } from 'antd';

import { IReferralLink } from './interfaces';
import ReferralLink from './ReferralLink';


interface IReferralLinks {
  links: IReferralLink[]
}
const ReferralLinks: FC<IReferralLinks> = (props) => {
  return (
    <Col span={24}>
      <h3>Referral link</h3>

      {props.links.map((link) => (
        <Row align='middle' key={link._id}>
          <ReferralLink
            refLink={link.refLink}
            isActive={link.isActive}
            payments={link.payments}
            registrations={link.registrations}
            createdAt={link.createdAt}
            transactionsAmount={link.transactionsAmount}
            clickThroughs={link.clickThroughs}
            _id={link._id}
          />
        </Row>
      ))}
    </Col>
  );
}

export default memo(ReferralLinks);
