import React, { FC, memo } from 'react';

import LoginForm from '../Components/LoginForm';

const LoginPage: FC = () => {
  return (
    <LoginForm />
  );
}

export default memo(LoginPage);
