import React, { FC, memo } from 'react';
import { IReferralLink } from './interfaces';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import './referralLinks.css';

const ReferralLink: FC<IReferralLink> = (props) => {
  const link = `https://r.affilink.io/${props.refLink}`;

  const onCopy = () => {
    navigator.clipboard.writeText(link);
    message.info('Copied to clipboard');
  }

  return (
    <>
      <span className='single_partner__referral_links__link'>{link}</span>
      <Button
        className='single_partner__referral_links__copy_btn'
        icon={<CopyOutlined />}
        type='text'
        onClick={onCopy}
      >
        Copy
      </Button>
    </>
  );
}

export default memo(ReferralLink)
