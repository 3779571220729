import { apiRequest } from '../../common/request';

interface IRequestAddProject {
  name: string;
  domain: string;
  currency: 'USD' | 'EUR';
}
export const requestAddProject = async (projectData: IRequestAddProject): Promise<[boolean, any]> => {
  const pathname = '/project/create';

  const [err, responseBody] = await apiRequest(pathname, projectData);
  if (err) {
    return [false, null];
  }

  if (responseBody?.status === 'failed') {
    return [false, responseBody];
  }

  return [true, responseBody];
}
