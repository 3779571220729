import { createBrowserRouter } from 'react-router-dom';

import Authorized from './Authorized';

import ProjectDashboardPage from './Pages/Project/Dashboard';
import ProjectPartnersPage from './Pages/Project/Partners';
import LoginPage from './Pages/Login';
import SignupPage from './Pages/Signup';
import CreateProjectPage from './Pages/Project/Create';
import HomePage from './Pages/Home';
import ProjectSettingsPage from './Pages/Project/Settings';
import CreatePartnerPage from './Pages/Project/CreatePartner';
import SinglePartnerPage from './Pages/Project/SinglePartner';
import { UpdateProjectContext } from './Pages/Project/helpers/updateProjectContext';
import InvoicesPage from './Pages/Project/Invoices';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Authorized><HomePage /></Authorized>,
  },
  {
    path: 'projects/:id',
    element: <Authorized><UpdateProjectContext><ProjectDashboardPage /></UpdateProjectContext></Authorized>,
  },
  {
    path: '/projects/:id/partners',
    element: <Authorized><UpdateProjectContext><ProjectPartnersPage /></UpdateProjectContext></Authorized>,
  },
  {
    path: '/projects/:id/partners/create',
    element: <Authorized><UpdateProjectContext><CreatePartnerPage /></UpdateProjectContext></Authorized>,
  },
  {
    path: '/projects/:id/partners/:partnerId',
    element: <Authorized><UpdateProjectContext><SinglePartnerPage /></UpdateProjectContext></Authorized>,
  },
  {
    path: '/projects/:id/invoices',
    element: <Authorized><UpdateProjectContext><InvoicesPage /></UpdateProjectContext></Authorized>,
  },
  {
    path: '/projects/:id/settings',
    element: <Authorized><UpdateProjectContext><ProjectSettingsPage /></UpdateProjectContext></Authorized>,
  },
  {
    path: '/projects/create',
    element: <Authorized><UpdateProjectContext><CreateProjectPage /></UpdateProjectContext></Authorized>,
  },
  {
    path: '/sign-in',
    element: <LoginPage />,
  },
  {
    path: '/sign-up',
    element: <SignupPage />,
  },
]);
