import React, { FC, memo, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { nameRules } from './helpers/validationRules';

import './addTokenForm.css';

interface IAddTokenForm {
  closeForm: () => void;
  submitForm: (name: string) => void;
}
const AddTokenForm: FC<IAddTokenForm> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFormSubmit = (values: any) => {
    setIsLoading(true);

    props.submitForm(values.name);
  }

  return (
    <Form requiredMark={false} layout='inline' onFinish={onFormSubmit} className='add_token__form'>
      <Row align='middle'>
        <Col span={16}>
          <Form.Item name='name' rules={nameRules}>
            <Input placeholder='Enter token name' />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button type='text' onClick={props.closeForm} icon={<CloseOutlined />}>Cancel</Button>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button htmlType='submit' loading={isLoading} icon={<CheckOutlined />} type='primary'>Create</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(AddTokenForm);
