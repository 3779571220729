import { createContext, FC, PropsWithChildren, useReducer } from 'react';
import { IProject, IProjectAction } from './interfaces';

export const ProjectContext = createContext<any>(null);

export const projectReducer = (currentProject: IProject, action: IProjectAction) => {
  switch (action.type) {
    case 'SET':
      return { ...currentProject, ...action.payload };
    default:
      return currentProject;
  }
}

export const ProjectContextProvider: FC<PropsWithChildren> = (props) => {
  const [project, dispatch] = useReducer(projectReducer, {
    id: '',
    name: '',
    baseUrl: '',
    publicId: '',
    isActive: true,
    registrations: 0,
    partnersPaymentsCount: 0,
    clickThroughs: 0,
    refRule: {
      fix: 0,
      percent: 0,
      pendingPeriod: 0,
    },
  });

  return (
    <ProjectContext.Provider value={{ ...project, dispatch }}>
      {props.children}
    </ProjectContext.Provider>
  )
}
