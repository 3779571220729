import React, { FC, memo, useEffect, useState } from 'react';
import { Button, Card, message, Space } from 'antd';
import { Link } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';

import './projectList.css';
import { requestProjects } from './api';
import { IProject } from './interfaces';

const ProjectsList: FC = () => {
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<IProject[]>([]);

  const updateProjectList = async () => {
    const [isRequestSucceed, response] = await requestProjects();

    if (!isRequestSucceed) {
      message.error('Error with projects list loading');
    }

    if (isRequestSucceed) {
      setProjects(response);
    }

    setIsListLoading(false);
  }

  useEffect(() => {
    updateProjectList();
  }, []);

  const renderContent = () => {
    if (isListLoading) {
      return (
        <>
          <Card className='project_list__card' loading={isListLoading} />
          <Card className='project_list__card' loading={isListLoading} />
          <Card className='project_list__card' loading={isListLoading} />
          <Card className='project_list__card' loading={isListLoading} />
        </>
      );
    }

    return projects.map((project) => (
      <>
        <Link to={`/projects/${project._id}`}>
          <Card title={project.name} className='project_list__card'>
            <p>Domain: {project.domain}</p>
            <p>Currency: {project.currency}</p>
          </Card>
        </Link>
      </>
    ));
  }

  return (
    <>
      <h1>
        Your projects {' '}
        <Link to='/projects/create'>
          <Button type='text' icon={<PlusCircleOutlined />}>Add project</Button>
        </Link>
      </h1>

      <Space wrap={true} size='middle'>
        {renderContent()}
      </Space>
    </>
  );
}

export default memo(ProjectsList);
