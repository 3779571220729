import React, { FC, memo } from 'react';
import { Menu, Layout, Dropdown, MenuProps } from 'antd';
import { HomeOutlined, LogoutOutlined, UserOutlined, SettingOutlined, WalletOutlined } from '@ant-design/icons';

import './layout.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ILayout } from './interfaces';
import { clearToken } from '../../common/auth';
import { useProject } from '../../hooks';

const { Sider, Header, Content } = Layout;

const MainLayout: FC<ILayout> = (props) => {
  const { pathname } = useLocation();
  const { id: projectId } = useParams();
  const navigate = useNavigate();

  const project = useProject();

  const logout = () => {
    clearToken();

    navigate('/sign-in');
  }

  const dropdownMenu: MenuProps['items'] = [
    // {
    //   key: 'settings',
    //   label: 'Settings',
    //   icon: <SettingOutlined />,
    // },
    {
      key: 'logout',
      label: 'Log out',
      icon: <LogoutOutlined />,
      onClick: logout,
    },
  ];

  const MainContent = () => (
    <Layout>
      <Header className='layout__content_layout__header'>
        <Link to='/'>Projects</Link>
        <div />

        <Dropdown menu={{ items: dropdownMenu }} arrow={true} placement='bottom' trigger={['click']}>
          <div className='layout__content_layout__header__user_dropdown__icon_container'>
            <UserOutlined />
          </div>
        </Dropdown>
      </Header>

      <Content className='layout__content_layout__page_content'>
        {props.children}
      </Content>
    </Layout>
  );

  if (!props.showSider) {
    return MainContent();
  }

  const siderUrls = {
    overview: `/projects/${projectId}`,
    partners: `/projects/${projectId}/partners`,
    invoices: `/projects/${projectId}/invoices`,
    settings: `/projects/${projectId}/settings`,
  };

  return (
    <Layout hasSider={true}>
      <Sider
        theme='light'
        className='layout__sider'
        breakpoint='lg'
        collapsedWidth={0}
      >

        <div className='layout__sider__project_title'>{project.name}</div>
        <Menu
          mode='vertical'
          selectedKeys={[pathname]}
          style={{ border: 'none' }}
        >
          <Menu.Item icon={<HomeOutlined />} key={siderUrls.overview}>
            <Link to={siderUrls.overview}>Dashboard</Link>
          </Menu.Item>
          <Menu.Item icon={<UserOutlined />} key={siderUrls.partners}>
            <Link to={siderUrls.partners}>Partners</Link>
          </Menu.Item>
          <Menu.Item icon={<WalletOutlined />} key={siderUrls.invoices}>
            <Link to={siderUrls.invoices}>Invoices</Link>
          </Menu.Item>
          <Menu.Item icon={<SettingOutlined />} key={siderUrls.settings}>
            <Link to={siderUrls.settings}>Project settings</Link>
          </Menu.Item>
        </Menu>
      </Sider>

      <MainContent />
    </Layout>
  );
}

export default memo(MainLayout);
