import React, { memo } from 'react';

import MainLayout from '../../Components/Layout';
import MainDashboard from '../../Components/MainDashboard';

const ProjectDashboardPage = () => {

  return (
    <MainLayout showSider={true}>
      <MainDashboard />
    </MainLayout>
  );
};

export default memo(ProjectDashboardPage);
