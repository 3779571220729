import { apiRequest } from '../../common/request';
export const requestProjectPartners = async (projectId: string, page: number) => {
  const pathname = '/partner/list';

  const requestBody = {
    project: projectId,
    page,
  }

  const [err, responseBody] = await apiRequest(pathname, requestBody);
  if (err) {
    return [false, null];
  }

  if (responseBody.statusCode === 404) {
    return [false, 'project_not_found'];
  }

  return [true, responseBody];
}
