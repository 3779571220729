import { FormRule } from 'antd';

export const nameRules: FormRule[] = [
  {
    required: true,
    type: 'string',
    whitespace: true,
    message: 'Please input name!',
  },
  {
    max: 256,
    message: 'Name is too long',
  },
];

export const mainDomainRules: FormRule[] = [
  {
    type: 'string',
    required: true,
    whitespace: true,
    message: 'Please input primary domain!',
  },
  {
    max: 256,
    message: 'Domain is too long',
  },
];

export const currencyRules: FormRule[] = [
  {
    required: true,
    message: 'Please select your primary currency',
  },
  {
    type: 'enum',
    enum: ['EUR', 'USD'],
    message: 'Only US Dollar and Euro supported now',
  }
]
