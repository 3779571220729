import { apiRequest } from '../../common/request';

interface IRequestCreatePartner {
  project: string;
  email: string;
  username?: string;
  description?: string;
}
export const requestCreatePartner = async (params: IRequestCreatePartner): Promise<[boolean, any]> => {
  const pathname = '/partner/create';

  const [err, responseBody] = await apiRequest(pathname, params);
  if (err) {
    return [false, null];
  }

  if (responseBody.statusCode === 400) {
    return [false, 'validation_error'];
  }

  if (responseBody?.statusCode === 404) {
    return [false, 'project_not_found'];
  }

  return [true, responseBody];
}
