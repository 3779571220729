import { apiRequest } from '../../common/request';

export const requestPartnerInfo = async (partnerId: string, projectId: string) => {
  const pathname = '/partner/get';

  const requestBody = {
    partner: partnerId,
    project: projectId,
  };

  const [err, responseBody] = await apiRequest(pathname, requestBody);
  if (err) {
    return [false, null];
  }

  if (responseBody.statusCode === 404) {
    return [false, 'partner_not_found'];
  }

  return [true, responseBody];
}
