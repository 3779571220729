import { apiRequest } from '../../common/request';
import { storeToken } from '../../common/auth';

export const requestSignIn = async (email: string, password: string): Promise<[boolean, any]> => {
  const pathname = '/user/login';

  const requestBody = {
    email,
    password,
  };

  const [err, responseBody] = await apiRequest(pathname, requestBody);
  if (err) {
    return [false, null];
  }

  if (responseBody?.status === 'failed') {
    console.log(responseBody);
    return [false, 'invalid_credentials'];
  }

  if (responseBody?.status === 'success' && responseBody?.token) {
    storeToken(responseBody.token);

    return [true, null];
  }

  return [false, null];
}
