import React, { memo } from 'react';

import MainLayout from '../../Components/Layout';
import ProjectPartners from '../../Components/ProjectPartners';

const PartnersPage = () => {

  return (
    <MainLayout showSider={true}>
      <ProjectPartners />
    </MainLayout>
  );
};

export default memo(PartnersPage);
