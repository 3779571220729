import { V1_LS_API_TOKEN_NAME } from '../config/constants';

export const storeToken = (token: string): void => {
  localStorage.setItem(V1_LS_API_TOKEN_NAME, token);
}

export const retrieveToken = (): string|null => {
  return localStorage.getItem(V1_LS_API_TOKEN_NAME);
}

export const clearToken = (): void => {
  localStorage.removeItem(V1_LS_API_TOKEN_NAME);
}
