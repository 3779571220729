import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Col, message, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { requestProjectInvoices, setInvoiceAsPaid } from './api';
import { IInvoice } from './interfaces';
import InvoicesTable from './InvoicesTable';

const ProjectInvoices: FC = () => {
  const [isInvoicesListLoading, setIsInvoicesListLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const updateInvoicesList = useCallback(async () => {
    const [isRequestSucceed, reason] = await requestProjectInvoices(id!);

    if (!isRequestSucceed) {
      if (reason === 'project_not_found') {
        message.error('Project has been deleted');
        return navigate('/');
      }
    }

    setIsInvoicesListLoading(false);
    setInvoices(reason.invoices || []);
  }, [id, navigate]);

  const setPaid = async (transactionId: string, invoiceId: string): Promise<boolean> => {
    const [isSuccess, reason] = await setInvoiceAsPaid(id!, transactionId, invoiceId);
    if (!isSuccess) {
      message.error('Cannot change invoice status');
      console.log(reason);
      return false;
    }

    message.success('Invoice successfully changed');
    const invoicesForUpdate = invoices.map((invoice) => {
      if (invoice._id === invoiceId) {
        invoice.paymentInfo.transactionId = transactionId;
        invoice.paid = true;
      }

      return invoice;
    });

    setInvoices(invoicesForUpdate);


    return true;
  }

  console.log(invoices);

  useEffect(() => {
    updateInvoicesList();
  }, [updateInvoicesList]);

  return (
    <>
      <h1>Invoices</h1>

      <Row>
        <Col span={24}>
          <InvoicesTable invoices={invoices} isLoading={isInvoicesListLoading} setInvoicePaid={setPaid} />
        </Col>
      </Row>
    </>
  );
}

export default memo(ProjectInvoices);
