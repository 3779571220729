import { FormRule } from 'antd';

export const emailRules: FormRule[] = [
  {
    type: 'string',
    whitespace: true,
    required: true,
    message: 'Please input email!',
  },
  {
    type: 'email',
    message: 'Please input valid email address!',
  }
];

export const passwordRules: FormRule[] = [
  {
    type: 'string',
    whitespace: true,
    required: true,
    message: 'Please input password!',
  },
  {
    min: 8,
    message: 'Password should have at least 8 symbols',
  },
  {
    max: 256,
    message: 'Password too long',
  },
];
