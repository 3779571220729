import { apiRequest } from '../../common/request';

export const requestProjectInvoices = async (projectId: string) => {
  const pathname = '/invoice/list';

  const requestBody = {
    project: projectId,
  };

  const [err, responseBody] = await apiRequest(pathname, requestBody);
  if (err) {
    return [false, null];
  }

  if (responseBody.statusCode === 404) {
    return [false, 'project_not_found'];
  }

  return [true, responseBody];
}

export const setInvoiceAsPaid = async (projectId: string, transactionId: string, invoiceId: string) => {
  const pathname = '/invoice/set-paid-status';

  const requestBody = {
    project: projectId,
    paid: true,
    transactionId,
    invoice: invoiceId,
  }

  const [err, responseBody] = await apiRequest(pathname, requestBody);
  if (err) {
    return [false, null];
  }

  if (responseBody.statusCode === 404) {
    return [false, 'project_not_found'];
  }

  return [true, responseBody];
}
