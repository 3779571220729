import React, { FC, memo, useState } from 'react';
import { Button, Col, Popconfirm, Row } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';

import './token.css';

interface ITokenView {
  name: string;
  isActive: boolean;
  changeIsActive: (tokenId: string, isActive: boolean) => boolean;
  deleteToken: () => Promise<boolean>;
  copyToken: () => Promise<boolean>;
}
const TokenView: FC<ITokenView> = (props) => {
  const [isCopyLoading, setIsCopyLoading] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const onDeleteClick = async () => {
    setIsDeleteLoading(true);
    const isTokenDeleted = await props.deleteToken();
    if (!isTokenDeleted) {
      setIsDeleteLoading(false);
    }
  }

  const onCopyClick = async () => {
    setIsCopyLoading(true);
    await props.copyToken();
    setIsCopyLoading(false);
  }

  return (
    <Row align='middle'>
      <Col span={16}>
        <span className='token_view__title'>{props.name}</span>
      </Col>
      <Col span={4}>
        <Button
          type='text'
          loading={isCopyLoading}
          onClick={onCopyClick}
          icon={<CopyOutlined />}
        >
          Copy
        </Button>
      </Col>

      <Col span={4}>
        <Popconfirm
          title='Delete this token?'
          description='This action cannot be undone'
          onConfirm={onDeleteClick}
        >
          <Button
            type='text'
            loading={isDeleteLoading}
            danger={true}
            icon={<DeleteOutlined />}
          >
            Delete
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );
}

export default memo(TokenView);
