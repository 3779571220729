import React, { FC, memo } from 'react';

import SignupForm from '../Components/SignupForm';

const SignupPage: FC = () => {
  return (
    <SignupForm />
  );
}

export default memo(SignupPage);
