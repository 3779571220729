import React, { FC, memo } from 'react';
import MainLayout from '../../Components/Layout';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import GeneralProjectSettings from '../../Components/ProjectSettings/General';
import APIProjectSettings from '../../Components/ProjectSettings/API';

const tabsItems: TabsProps['items'] = [
  {
    key: 'general',
    label: 'General',
    children: <GeneralProjectSettings />,
  },
  {
    key: 'api',
    label: 'API',
    children: <APIProjectSettings />
  }
]

const ProjectSettingsPage: FC = () => {
  return (
    <MainLayout showSider={true}>
      <Tabs defaultActiveKey='general' items={tabsItems} />
    </MainLayout>
  );
}

export default memo(ProjectSettingsPage);
