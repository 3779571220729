import React, { FC, memo } from 'react';
import { Card, Space, Statistic } from 'antd';
import CountUp from 'react-countup';

import './mainDashboard.css';
import { useProject } from '../../hooks';

const formatter = (value: number) => <CountUp end={value} separator=',' />

const MainDashboard: FC = () => {

  const projectContext = useProject();

  return (
    <>
      <h1>Main dashboard</h1>
      <Space wrap={true} size='middle'>
        <Card bordered={true} className='main_dashboard__card'>
          <Statistic title='Clicks' value={projectContext.clickThroughs} formatter={formatter as any} />
        </Card>

        <Card bordered={true} className='main_dashboard__card'>
          <Statistic title='Registrations' value={projectContext.registrations} formatter={formatter as any} />
        </Card>

        <Card bordered={true} className='main_dashboard__card'>
          <Statistic title='Payments' value={projectContext.partnersPaymentsCount} formatter={formatter as any} />
        </Card>
      </Space>
    </>
  );
}

export default memo(MainDashboard);
