import React, { FC, memo } from 'react';

import MainLayout from '../../Components/Layout';
import SinglePartner from '../../Components/SinglePartner';

const SinglePartnerPage: FC = () => {
  return (
    <MainLayout showSider={true}>
      <SinglePartner />
    </MainLayout>
  );
}

export default memo(SinglePartnerPage);
