import React, { FC, memo } from 'react';

import AddProjectForm from '../../Components/AddProjectForm';
import MainLayout from '../../Components/Layout';

const CreateProjectPage: FC = () => {

  return (
    <MainLayout showSider={false}>
      <AddProjectForm />
    </MainLayout>
  );
}

export default memo(CreateProjectPage);
