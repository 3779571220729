import React, { FC, memo, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import { nameRules, currencyRules, mainDomainRules } from './helpers/validationRules';

import './addProjectForm.css';
import { requestAddProject } from './api';

const { Option } = Select;

const AddProjectForm: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const onFormFinished = async (values: any) => {
    setIsLoading(true);

    const [isRequestSucceed, reason] = await requestAddProject(values);
    if (isRequestSucceed) {
      const { _id } = reason;

      return navigate(`/projects/${_id}`);
    }

    console.error('ADD_PROJECT_REQUEST_ERROR::', reason);
    message.error('Some problems with project creation. We are already working on this issue');

    setIsLoading(false);
  }

  return (
    <Row align='middle' justify='center' className='add_project_form__wrapper'>
      <Col span={6} xs={{ span: 18 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 6 }}>
        <h1>Setup project</h1>

        <Form layout='vertical' requiredMark={false} onFinish={onFormFinished}>
          <Form.Item
            name='name'
            label='Project name:'
            rules={nameRules}
          >
            <Input placeholder='Enter project name' />
          </Form.Item>

          <Form.Item
            name='domain'
            label='Primary domain:'
            rules={mainDomainRules}
          >
            <Input placeholder='eg: www.example.com' />
          </Form.Item>

          <Form.Item
            name='currency'
            label='Currency:'
            rules={currencyRules}
          >
            <Select placeholder='Select your currency'>
              <Option value='USD'>$ - Dollar US</Option>
              <Option value='EUR'>€ - Euro</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={isLoading}
              className='add_project_form__submit_btn'
            >
              Create project
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default memo(AddProjectForm);
