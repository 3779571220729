import React, { FC, memo, useState } from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { requestCreatePartner } from './api';
import { emailRules } from './helpers/validationRules';

import './addPartnerForm.css';

const AddPartnerForm: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const onFormSubmit = async (values: any) => {
    setIsLoading(true);

    const [isPartnerCreated, reason] = await requestCreatePartner({
      email: values.email,
      project: id!,
      username: values.username,
      description: values.description,
    });

    if (isPartnerCreated) {
      return navigate(`/projects/${id}/partners/${reason._id}`);
    }

    setIsLoading(false);

    if (reason === 'project_not_found') {
      message.error('Project not found');
      return navigate('/');
    }

    message.error('Cannot create partner');
  }

  return (
    <Row align='middle' justify='center' className='add_partner_form__wrapper'>
      <Col span={6} xs={{ span: 18 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 6 }}>
        <h1>Create partner</h1>

        <Form layout='vertical' requiredMark={false} onFinish={onFormSubmit}>
          <Form.Item
            name='email'
            label='Partner email:'
            rules={emailRules}
          >
            <Input placeholder='Enter partner email' autoComplete='off' />
          </Form.Item>
          <Form.Item
            name='username'
            label='Partner username:'
          >
            <Input placeholder='John Smith' autoComplete='off' />
          </Form.Item>

          <Form.Item
            name='description'
            label='Description:'
          >
            <Input autoComplete='off' />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='add_partner_form__submit_btn'
              loading={isLoading}
            >
              Create partner
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default memo(AddPartnerForm);
