import React, { FC, memo, useState } from 'react';
import { IInvoice } from '../interfaces';
import { Button, Form, Input } from 'antd';

interface ITransactionCell extends IInvoice {
  setPaidStatus: (transactionId: string, invoiceId: string) => Promise<boolean>;
}
const TransactionCell: FC<ITransactionCell> = (props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [form] = Form.useForm();

  const startEdit = () => {
    setIsEditing(true);
  }

  const cancelEdit = () => {
    form.setFieldValue('transactionId', '');
    setIsSaving(false);
    setIsEditing(false);
  }

  const onFormFinish = async (values: any) => {
    setIsSaving(true);

    const isSuccess = await props.setPaidStatus(values.transactionId, props._id);
    if (!isSuccess) {
      setIsSaving(false);
      return;
    }

    cancelEdit();
  }

  if (isEditing) {
    return (
      <Form form={form} requiredMark={false} colon={false} layout='inline' onFinish={onFormFinish}>
        <Form.Item name='transactionId' rules={[{ required: true, message: 'Enter paypal transaction' }]}>
          <Input placeholder='Paypal transaction' />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isSaving}>Save</Button>
        </Form.Item>

        <Button type='link' disabled={isSaving} onClick={cancelEdit}>Cancel</Button>
      </Form>
    )
  }

  if (!props.paid) {
    return (
      <Button type='primary' onClick={startEdit}>Set as paid</Button>
    );
  }

  return (
    <>{props.paymentInfo.transactionId}</>
  );
}

export default memo(TransactionCell);
