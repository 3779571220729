import React, { FC, memo, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { emailRules, passwordRules } from './helpers/validationRules';
import { requestSignIn } from './api';

import './loginForm.css';

const LoginForm: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const onFormFinished = async (values: any) => {
    setIsLoading(true);
    const [isRequestSucceed, reason] = await requestSignIn(values.username, values.password);
    if (!isRequestSucceed && reason === 'invalid_credentials') {
      message.error('Incorrect email or password');
    }

    if (isRequestSucceed) {
      navigate('/', {
        replace: true,
      });

      return;
    }

    setIsLoading(false);
  }

  return (
    <Row align='middle' justify='center' className='login_page__wrapper'>
      <Col span={6} xs={{ span: 18 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 6 }}>
        <h1>Sign in</h1>
        <Form onFinish={onFormFinished}>
          <Form.Item
            name='username'
            rules={emailRules}
          >
            <Input prefix={<UserOutlined />} placeholder='Email address' />
          </Form.Item>
          <Form.Item
            name='password'
            rules={passwordRules}
          >
            <Input.Password prefix={<LockOutlined />} placeholder='Password' type='password' />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='login_page__submit_btn'
              loading={isLoading}
            >
              Sign in
            </Button>

            <br/>

            <Link to='/sign-up'>Create account</Link>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default memo(LoginForm);
