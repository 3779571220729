import React, { FC, memo, useState } from 'react';
import { Form, InputNumber, Button, message, Row, Col } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { fixPriceRule, pendingPeriodRule, percentRule } from './helpers/validationRules';
import { requestUpdateRewards } from './api';
import { useProject } from '../../../hooks';

interface IRewardRuleForm {
  percent: number;
  fix: number;
  pendingPeriod: number;
}
const RewardRuleForm: FC<IRewardRuleForm> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const project = useProject();

  const onFormFinished = async (values: any) => {
    setIsLoading(true);

    const [isRequestSucceed, reason] = await requestUpdateRewards(id!, values);

    if (!isRequestSucceed) {
      if (reason === 'project_not_found') {
        message.error('Project not found');
        return navigate('/');
      }
    }

    project.dispatch({
      type: 'SET',
      payload: {
        refRule: values,
      },
    });

    setIsLoading(false);
  }

  return (
    <Row>
      <Col span={6} xs={{ span: 18 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 6 }}>
        <Form layout='vertical' requiredMark={false} onFinish={onFormFinished}>
          <Form.Item
            name='pendingPeriod'
            label='Pending period (days)'
            rules={pendingPeriodRule}
            initialValue={props.pendingPeriod}
          >
            <InputNumber
              className='project_settings__general__project_info__section__rewards_settings__input'
              placeholder='Enter pending period'
            />
          </Form.Item>

          <Form.Item
            name='percent'
            label='Percents'
            rules={percentRule}
            initialValue={props.percent}
          >
            <InputNumber
              placeholder='Enter pending period'
              className='project_settings__general__project_info__section__rewards_settings__input'
            />
          </Form.Item>

          <Form.Item
            name='fix'
            label='Fix price'
            rules={fixPriceRule}
            initialValue={props.fix}
          >
            <InputNumber
              placeholder='Enter pending period'
              className='project_settings__general__project_info__section__rewards_settings__input'
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              htmlType='submit'
              type='primary'
              className='project_settings__general__project_info__section__rewards_settings__input'
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default memo(RewardRuleForm);
