import React, { FC, memo } from 'react';
import { Table } from 'antd';
import { Link, useParams } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';

import { IInvoice } from '../interfaces';
import TransactionCell from './TransactionCell';

interface IInvoicesTable {
  invoices: IInvoice[];
  isLoading: boolean;
  setInvoicePaid: (transactionId: string, invoiceId: string) => Promise<boolean>;
}
const InvoicesTable: FC<IInvoicesTable> = (props) => {
  const { id } = useParams();

  const columns: ColumnsType<IInvoice> = [
    {
      title: 'Partner',
      dataIndex: 'partnerEmail',
      render: (email, { publicPartnerId }) => <Link to={`/projects/${id}/partners/${publicPartnerId}`}>{email}</Link>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount'
    },
    {
      title: 'Paypal email',
      dataIndex: 'paymentInfo',
      render: (paymentInfo) => paymentInfo.email,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      render: (_, data) => <TransactionCell {...data as any} setPaidStatus={props.setInvoicePaid} />,
      width: 390,
    }
  ];

  return (
    <>
      <Table dataSource={props.invoices} columns={columns} loading={props.isLoading} scroll={{ x: true }} />
    </>
  );
}

export default memo(InvoicesTable);
