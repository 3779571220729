import { API_BASE_URL } from '../config/constants';
import { clearToken, retrieveToken } from './auth';
import { notification } from 'antd';

export const apiRequest = async (pathname: string, body: object) => {
  const token = retrieveToken();

  const requestConfig: RequestInit = {
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(`${API_BASE_URL}${pathname}`, requestConfig)

    if (!response.ok) {
      console.error('Request failed');

      notification.error({
        message: 'Service under maintenance',
        description: 'We are already working on fixing the problem. In the meantime, you have time for a cup of coffee.',
      });

      return [response, null];
    }

    const serverData = await response.json();

    if (serverData.statusCode === 401) {
      clearToken();
      window.location.href = '/sign-in';
      return [null, null];
    }

    return [null, serverData];
  } catch (e) {
    notification.error({
      message: 'Service under maintenance',
      description: 'We are already working on fixing the problem. In the meantime, you have time for a cup of coffee.',
    });

    return [e, null];
  }
}
