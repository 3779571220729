import React, { FC, memo } from 'react';
import { Button, Col, Divider, message } from 'antd';

import './partnerInfo.css';

interface IPartnerInfo {
  email: string;
  partnerId: string;
  username: string;
  description: string;
}
const PartnerInfo: FC<IPartnerInfo> = (props) => {
  const copyPartnerId = () => {
    navigator.clipboard.writeText(props.partnerId);
    message.info('Copied to clipboard');
  }

  return (
    <Col span={22}>
      <span className='single_partner__partner_info__email'>{props.email}</span>

      <Divider />

      <h3>Metadata</h3>
      <Button size='small' onClick={copyPartnerId}>{props.partnerId || ''}</Button>

      {/*<div className='single_partner__partner_info__detail_title'>id</div>*/}
      {/*<div className='single_partner__partner_info__detail_value'>asdasdasadasdasd</div>*/}

      {/*<div className='single_partner__partner_info__detail_title'>id</div>*/}
      {/*<div className='single_partner__partner_info__detail_value'>asdasdasadasdasd</div>*/}

      {/*<div className='single_partner__partner_info__detail_title'>id</div>*/}
      {/*<div className='single_partner__partner_info__detail_value'>asdasdasadasdasd</div>*/}
    </Col>
  );
}

export default memo(PartnerInfo);
