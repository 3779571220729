import { apiRequest } from '../../../../common/request';
export const requestProjectInfo = async (projectId: string) => {
  const pathname = `/project/${projectId}/get`;

  const [err, responseBody] = await apiRequest(pathname, {});
  if (err) {
    return [false, null];
  }

  if (responseBody.statusCode === 404) {
    return [false, 'project_not_found'];
  }

  return [true, responseBody];
}
