import React, { FC, memo } from 'react';
import MainLayout from '../Components/Layout';
import ProjectsList from '../Components/ProjectsList';

const HomePage: FC = () => {
  return (
    <MainLayout showSider={false}>
      <ProjectsList />
    </MainLayout>
  );
}

export default memo(HomePage);
