import { FormRule } from 'antd';

export const emailRules: FormRule[] = [
  {
    type: 'string',
    whitespace: true,
    required: true,
    message: 'Please input email!',
  },
  {
    type: 'email',
    message: 'Please input valid email address!',
  },
  {
    max: 256,
    message: 'Email is too long',
  },
];

export const passwordRules: FormRule[] = [
  {
    type: 'string',
    whitespace: true,
    required: true,
    message: 'Please input password!',
  },
  {
    min: 8,
    message: 'Password should have at least 8 symbols',
  },
  {
    max: 256,
    message: 'Password is too long',
  }
];

export const confirmPasswordRules: FormRule[] = [
  {
    required: true,
    message: 'Please confirm password!',
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }

      return Promise.reject(new Error('Passwords does not match!'));
    }
  }),
]
