import { apiRequest } from '../../common/request';
import { storeToken } from '../../common/auth';

export const requestSignUp = async (
  email: string,
  password: string,
  passwordConfirm: string,
): Promise<[boolean, any]> => {
  const pathname = '/user/create';

  const requestBody = {
    email,
    password,
    passwordConfirm,
  };

  const [err, responseBody] = await apiRequest(pathname, requestBody);
  if (err) {
    return [false, null];
  }

  if (responseBody?.status === 'failed') {
    if (responseBody?.message?.toLowerCase() === 'passwords do not match') {
      return [false, 'match_passwords'];
    }

    if (responseBody?.message?.toLowerCase() === 'user already registered') {
      return [false, 'user_exists'];
    }
  }

  if (responseBody?.status === 'success' && responseBody?.token) {
    storeToken(responseBody.token);

    return [true, null];
  }

  return [false, null];
}
