import React, { FC, memo, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { requestSignUp } from './api';
import { confirmPasswordRules, emailRules, passwordRules } from './helpers/validationRules';

import './signupForm.css';

const SignupForm: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const onFormFinished = async (values: any = {}) => {
    const { username, password, passwordConfirm } = values;

    setIsLoading(true);

    const [isRequestSucceed, reason] = await requestSignUp(username, password, passwordConfirm);

    if (!isRequestSucceed) {
      if (reason === 'match_passwords') {
        message.error('Passwords do not match');
      }

      if (reason === 'user_exists') {
        message.error('User already exists');
      }
    }

    if (isRequestSucceed) {
      navigate('/projects/create', {
        replace: true,
      });
    }

    setIsLoading(false);
  }

  return (
    <Row align='middle' justify='center' className='signup_page__wrapper'>
      <Col span={6} xs={{ span: 18 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 6 }}>
        <h1>Sign up</h1>

        <Form onFinish={onFormFinished}>
          <Form.Item
            name='username'
            rules={emailRules}
          >
            <Input prefix={<UserOutlined />} placeholder='Email address' />
          </Form.Item>
          <Form.Item
            name='password'
            rules={passwordRules}
          >
            <Input.Password prefix={<LockOutlined />} placeholder='Password' type='password' />
          </Form.Item>
          <Form.Item
            name='passwordConfirm'
            rules={confirmPasswordRules}
          >
            <Input.Password prefix={<LockOutlined />} placeholder='Confirm password' type='password' />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              loading={isLoading}
              htmlType='submit'
              className='login_page__submit_btn'
            >
              Sign up
            </Button>

            <br/>

            I already have account <Link to='/sign-in'>sign in</Link>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default memo(SignupForm);
