import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Col, Divider, message, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import PartnerInfo from './PartnerInfo';
import PartnerStatistic from './PartnerStatistic';
import ReferralLinks from './ReferralLinks';
import { requestPartnerInfo } from './api';
import { IReferralLink } from './ReferralLinks/interfaces';


interface IPartner {
  _id: string;
  publicId: string;
  email: string;
  balance: number;
  clickThroughs: number;
  payments: number;
  registrations: number;
  transactionsAmount: number;
  refLinks: IReferralLink[];
  username?: string;
  description?: string;
}
const SinglePartner: FC = () => {
  const [partner, setPartner] = useState<IPartner|null>();

  const { partnerId, id } = useParams();

  const navigate = useNavigate();

  const getPartnerInfo = useCallback(async () => {
    const [isRequestSucceed, reason] = await requestPartnerInfo(partnerId!, id!);
    if (!isRequestSucceed) {
      message.error('Partner not found');
      return navigate(`/projects/${id}/partners`);
    }

    setPartner({
      _id: reason._id,
      publicId: reason.publicId,
      email: reason.email,
      username: reason.username,
      description: reason.description,
      balance: reason.balance ?? 0,
      payments: reason.payments ?? 0,
      registrations: reason.registrations ?? 0,
      clickThroughs: reason.clickThroughs ?? 0,
      transactionsAmount: reason.transactionsAmount ?? 0,
      refLinks: reason.refLinks ?? [],
    });
  }, [id, navigate, partnerId]);

  useEffect(() => {
    getPartnerInfo();
  }, [getPartnerInfo]);

  return (
    <Row wrap={true}>
      <Col span={6} xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }} xl={{ span: 6 }}>
        <PartnerInfo
          partnerId={partner?.publicId ?? ''}
          email={partner?.email ?? ''}
          description={partner?.description ?? ''}
          username={partner?.username ?? ''}
        />
      </Col>
      <Col span={18} xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 14 }} xl={{ span: 18 }}>
        <PartnerStatistic
          balance={partner?.balance ?? 0}
          registrations={partner?.registrations ?? 0}
          payments={partner?.payments ?? 0}
          transactionsAmount={partner?.transactionsAmount ?? 0}
          clicks={partner?.clickThroughs ?? 0}
        />

        <Divider />

        <ReferralLinks links={partner?.refLinks ?? []} />
      </Col>
    </Row>
  );
}

export default memo(SinglePartner);
