import React, { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { requestProjectInfo } from './api';
import { useProject } from '../../../../hooks';

export const UpdateProjectContext: FC<PropsWithChildren> = (props) => {
  const { id } = useParams();

  const projectContext = useProject();

  const updateProjectInfo = useCallback(async () => {
    const [isRequestSucceed, reason] = await requestProjectInfo(id!);

    if (!isRequestSucceed) {
      return;
    }

    projectContext.dispatch({
      type: 'SET',
      payload: {
        id,
        name: reason.name,
        domain: reason.domain,
        baseUrl: reason.baseUrl,
        publicId: reason.publicId,
        isActive: reason.isActive,
        registrations: reason.registrations ?? 0,
        partnersPaymentsCount: reason.partnersPaymentsCount ?? 0,
        clickThroughs: reason.clickThroughs ?? 0,
        refRule: {
          fix: reason.refRule.fix ?? 0,
          percent: reason.refRule.percent ?? 0,
          pendingPeriod: reason.refRule.pendingPeriod ?? 0,
        },
        logo: reason.logo ?? '',
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    updateProjectInfo();
  }, [id, updateProjectInfo]);

  return (
    <>{props.children}</>
  );
}
