import { apiRequest } from '../../../common/request';

export const requestApiTokens = async (projectId: string): Promise<[boolean, any]> => {
  const pathname = '/api-token/list';

  const requestBody = {
    project: projectId,
  };

  const [err, responseBody] = await apiRequest(pathname, requestBody);
  if (err) {
    return [false, null];
  }

  if (responseBody?.statusCode === 404) {
    return [false, 'project_not_found'];
  }

  return [true, responseBody];
}

export const requestCreateToken = async (projectId: string, name: string): Promise<[boolean, any]> => {
  const pathname = '/api-token/create';

  const requestBody = {
    project: projectId,
    name,
  };

  const [err, responseBody] = await apiRequest(pathname, requestBody);
  if (err) {
    return [false, null];
  }

  if (responseBody?.statusCode === 404) {
    return [false, 'project_not_found'];
  }

  return [true, responseBody];
}

export const requestDeleteToken = async (tokenId: string, projectId: string): Promise<[boolean, any]> => {
  const pathname = '/api-token/delete';

  const requestBody = {
    apiTokenId: tokenId,
    project: projectId,
  };

  const [err, responseBody] = await apiRequest(pathname, requestBody);
  if (err) {
    return [false, null];
  }

  return [true, responseBody];
}

export const requestGetToken = async (tokenId: string, projectId: string): Promise<[boolean, any]> => {
  const pathname = '/api-token/get';

  const requestBody = {
    apiTokenId: tokenId,
    project: projectId,
  };

  const [err, responseBody] = await apiRequest(pathname, requestBody);
  if (err) {
    return [false, null];
  }

  if (responseBody?.status === 'failure') {
    if (responseBody.statusCode === 404) {

      const errMessages = {
        'project not found': 'project_not_found',
        'token not found': 'token_not_found',
      };

      return [false, errMessages[responseBody.message as 'project not found' | 'token not found']];
    }

    return [false, null];
  }

  return [true, responseBody];
}
