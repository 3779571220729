import { FormRule } from 'antd';

export const emailRules: FormRule[] = [
  {
    type: 'string',
    whitespace: true,
    required: true,
    message: 'Please input email!',
  },
  {
    type: 'email',
    message: 'Please input valid email address!',
  }
];
