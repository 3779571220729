import { apiRequest } from '../../../common/request';

export const requestDeleteProject = async (projectId: string): Promise<[boolean, any]> => {
  const pathname = `/project/${projectId}/delete`;

  const [err, responseBody] = await apiRequest(pathname, {});
  if (err) {
    return [false, null];
  }

  return [true, responseBody];
}

interface IRequestUpdateRewards {
  pendingPeriod: number;
  percent: number;
  fix: number;
}
export const requestUpdateRewards = async (projectId: string, rewardsRule: IRequestUpdateRewards): Promise<[boolean, any]> => {
  const pathname = `/project/${projectId}/update-ref-rule`;

  const [err, responseBody] = await apiRequest(pathname, rewardsRule);
  if (err) {
    return [false, null];
  }

  if (responseBody.statusCode === 404) {
    return [false, 'project_not_found'];
  }

  return [true, responseBody];
}
