import React, { FC, memo, useState } from 'react';
import { Button, Col, Divider, message, Popconfirm, Row } from 'antd';
import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { requestDeleteProject } from './api';
import { useProject } from '../../../hooks';

import './generalSettings.css';
import RewardRuleForm from './RewardRuleForm';

const GeneralProjectSettings: FC = () => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const project = useProject();

  const deleteProject = async () => {
    setIsDeleting(true);

    await requestDeleteProject(id!);

    navigate('/');
  }

  const copyProjectId = () => {
    navigator.clipboard.writeText(project.publicId);
    message.info('Copied to clipboard');
  }

  return (
    <>
      <h1>General settings</h1>

      <Row>
        <h4>Project info</h4>
      </Row>
      <Row align='middle'>
        <Col span={2}>Project ID:</Col>
        <Col span={4}>
          <Button size='small' icon={<CopyOutlined />} onClick={copyProjectId}>{project.publicId}</Button>
        </Col>
      </Row>
      <Row align='middle' className='project_settings__general__project_info__section'>
        <Col span={2}>Project name:</Col>
        <Col span={4}>
          <span>{project.name}</span>
        </Col>
      </Row>
      <Row align='middle' className='project_settings__general__project_info__section'>
        <Col span={2}>Domain:</Col>
        <Col span={4}>
          <span>{project.domain}</span>
        </Col>
      </Row>
      <Row align='middle' className='project_settings__general__project_info__section'>
        <Col span={2}>Base URL:</Col>
        <Col span={4}>
          <span>{project.baseUrl}</span>
        </Col>
      </Row>

      <Divider />

      <Row>
        <h4>Rewards settings</h4>
      </Row>

      {project.id && (
        <RewardRuleForm
          fix={project.refRule?.fix}
          pendingPeriod={project.refRule?.pendingPeriod}
          percent={project.refRule?.percent}
        />
      )}

      <Divider />

      <Row>
        <h4>Delete project</h4>
      </Row>
      <Row>
        <Popconfirm
          title='Delete this project?'
          description='This action cannot be undone'
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onConfirm={deleteProject}
        >
          <Button type='primary' danger={true} loading={isDeleting}>Delete project</Button>
        </Popconfirm>
      </Row>
    </>
  );
}

export default memo(GeneralProjectSettings);
