import { apiRequest } from '../../common/request';

export const requestProjects = async (): Promise<[boolean, any]> => {
  const pathname = '/project/list';

  const [err, responseBody] = await apiRequest(pathname, {});
  if (err) {
    return [false, null];
  }

  if (responseBody?.status === 'failed') {
    console.log(responseBody);
    return [false, null];
  }

  if (responseBody?.status === 'success') {
    return [true, responseBody.projects];
  }

  return [false, null];
}
