import React, { FC, memo } from 'react';
import { CopyOutlined } from '@ant-design/icons';

import './appCode.css';
import { Button, message } from 'antd';

const CODE_FRAGMENT = `
<script type="text/javascript">
  (function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "ie283vgria");
</script>
`;

const AppCode: FC = () => {
  const onCopyClick = () => {
    navigator.clipboard.writeText(CODE_FRAGMENT);
    message.info('Copied to clipboard');
  }

  return (
    <div className='app_code__container'>
      <Button
        className='app_code__copy_btn'
        type='text'
        icon={<CopyOutlined />}
        onClick={onCopyClick}
      >
        Copy
      </Button>
      <pre className='app_code__pre'>
        <code>
          {CODE_FRAGMENT.toString()}
        </code>
      </pre>
    </div>
  );
}

export default memo(AppCode);
