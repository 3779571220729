import React, { FC, memo, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { retrieveToken } from './common/auth';

const Authorized: FC<PropsWithChildren> = (props) => {
  const [renderPage, setRenderPage] = useState(false);

  const navigate = useNavigate();

  const checkAuthorization = useCallback(() => {
    const isTokenExists = retrieveToken();
    if (isTokenExists) {
      setRenderPage(true);
      return;
    }

    navigate('/sign-in', {
      replace: true,
    });

    return;
  }, [navigate]);

  useEffect(() => {
    checkAuthorization();
  }, [checkAuthorization]);

  return (
    <>
      {renderPage && props.children}
    </>
  );
}

export default memo(Authorized);
