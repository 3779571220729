import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, message, Row, Table } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { requestProjectPartners } from './api';

interface IPartner {
  readonly _id: string;
  readonly publicId: string;
  readonly email: string;
  readonly balance: number;
  readonly clickThroughs: number;
  readonly registrations: number;
  readonly payments: number;
  readonly transactionsAmount: number;
}

const ProjectPartners: FC = () => {
  const [isPartnersListLoading, setIsPartnersListLoading] = useState<boolean>(true);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPartners, setTotalPartners] = useState<number>(0);

  const { id } = useParams();
  const navigate = useNavigate();

  const getPartnersList = useCallback(async () => {
    setIsPartnersListLoading(true);
    const [isRequestSucceed, reason] = await requestProjectPartners(id!, page);

    if (!isRequestSucceed) {
      if (reason === 'project_not_found') {
        message.error('Project has been deleted');
        return navigate('/');
      }
    }

    setPartners(reason.partners || []);
    setTotalPartners(reason.count || 0);
    setIsPartnersListLoading(false);
  }, [id, navigate, page]);

  useEffect(() => {
    getPartnersList();
  }, [getPartnersList]);


  const columns: ColumnsType<IPartner> = [
    {
      title: 'Email',
      dataIndex: 'email',
      render: (username, { publicId }) => <Link to={`/projects/${id!}/partners/${publicId}`}>{username}</Link>
    },
    {
      title: 'Clicks',
      dataIndex: 'clickThroughs',
    },
    {
      title: 'Registrations',
      dataIndex: 'registrations',
    },
    {
      title: 'Payments',
      dataIndex: 'payments',
    },
    {
      title: 'Transactions amount',
      dataIndex: 'transactionsAmount',
      render: (amount) => <span>${amount}</span>,
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (amount) => <span>${amount}</span>,
    },
  ];


  return (
    <Row>
      <h1>
        Partners {' '}
        <Link to='create'>
          <Button type='text' icon={<PlusCircleOutlined />}>Add partner</Button>
        </Link>
      </h1>
      <Col span={24}>
        <Table
          loading={isPartnersListLoading}
          columns={columns}
          dataSource={partners}
          scroll={{ x: true }}
          pagination={{
            current: page,
            onChange: (pageNum) => setPage(pageNum),
            total: totalPartners,
            pageSize: 10,
          }}
        />
      </Col>
    </Row>
  );
}

export default memo(ProjectPartners);
