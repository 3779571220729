import React, { FC, memo } from 'react';

import MainLayout from '../../Components/Layout';
import ProjectInvoices from '../../Components/ProjectInvoices';

const InvoicesPage: FC = () => {
  return (
    <MainLayout showSider={true}>
      <ProjectInvoices />
    </MainLayout>
  );
}

export default memo(InvoicesPage);
